export default [
  {
    name: 'Elba Soft - Manufacturing',
    sites: [
      {
        name: 'Elbasoft',
        url: 'http://staging.elbasoft.com/Login.aspx',
      },
      {
        name: 'Elbasoft Demo',
        url: 'http://demo.elbasoft.com/Login.aspx',
      },
      {
        name: 'SF / LCL Analytics',
        url:
          'https://datastudio.google.com/reporting/36444ae9-29ee-465d-8b7b-2bf83ac2066b/page/2T17',
      },
    ],
  },
  {
    name: 'Sophia Fiori',
    sites: [
      {
        name: 'sophiafiori.com',
        url: 'http://www.sophiafiori.com/',
      },
      {
        name: 'new.sophiafiori.com',
        url: 'http://new.sophiafiori.com/',
      },
      {
        name: 'Landing Pages',
        url: 'http://design.sophiafiori.com/sitemap/',
      },
      {
        name: 'Careers Home',
        url: 'http://careers.sophiafiori.com/',
      },
      {
        name: 'Disney',
        url: 'http://magicaljewelrycollection.com/',
      },
      {
        name: 'Diamonds on Holland',
        url: 'http://diamondsonholland.com',
      },
    ],
  },
  {
    name: 'Last Call Luxury',
    sites: [
      {
        name: 'Retail Login',
        url: 'http://lcl.elbasoft.com/login.aspx',
      },
      {
        name: 'lastcallluxury.com',
        url: 'https://lastcallluxury.com/',
      },
      {
        name: 'demo.lastcallluxury.com',
        url: 'https://demo.lastcallluxury.com/',
      },
    ],
  },
  {
    name: 'Circles',
    sites: [
      {
        name: 'circlesnow.com',
        url: 'http://circlesnow.com',
      },
      {
        name: 'demo.circlesnow.com',
        url: 'http://demo.circlesnow.com/',
      },
      {
        name: 'Mobile Application',
        url: 'http://app.circlesnow.com',
      },
      {
        name: 'Demo Mobile Application',
        url: 'http://demo.app.circlesnow.com/',
      },
      {
        name: 'Admin Panel',
        url: 'https://admin.circlesnow.com/',
      },
      {
        name: 'Demo Admin Panel',
        url: 'https://demo.admin.circlesnow.com/',
      },
    ],
  },
  {
    name: 'CTInfo',
    sites: [
      {
        name: 'ctinfo.in',
        url: 'http://ctinfo.in/',
      },
      {
        name: 'CTInfo Time Clock',
        url: 'http://demo.elbasoft.com/lm/',
      },
      {
        name: 'Careers Home',
        url: 'http://careers.ctinfo.in',
      },
    ],
  },
  {
    name: 'Goldbrick Publishing',
    sites: [
      {
        name: 'goldbrickpub.com',
        url: 'https://goldbrickpub.com/',
      },
      {
        name: 'Athoe',
        url: 'http://athoe.org/',
      },
      {
        name: 'AHRK Path',
        url: 'https://ahrkpath.com/',
      },
    ],
  },
  {
    name: 'Blogs',
    sites: [
      {
        name: 'Art & Diamonds',
        url: 'http://www.artanddiamonds.com',
      },
      {
        name: 'Critic Hotshot',
        url: 'http://www.critichotshot.com',
      },
      {
        name: 'Cruise Hotshot',
        url: 'http://www.cruisehotshot.com',
      },
      {
        name: 'Shopping Hotshot',
        url: 'http://www.shoppinghotshot.com',
      },
      {
        name: 'SF Jewelry Reviews',
        url: 'http://www.sophiafiorijewelryreviews.com',
      },
      {
        name: 'SF Reviews',
        url: 'http://www.sophiafiorireviews.com',
      },
      {
        name: 'Wedding Plan',
        url: 'http://www.weddingplanblog.com',
      },
    ],
  },
];
