import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-site-group',
  templateUrl: './site-group.component.html',
  styleUrls: ['./site-group.component.scss'],
})
export class SiteGroupComponent implements OnInit {
  @Input() siteGroup: any;

  ngOnInit(): void {}
}
